export const state = () => ({
  loadingAnimationFinished: false,
  previewMode: false,
  isMobile: false,
  isLenisScrollInitialized: false,
  showNav: true,
  scrollToTarget: null,
  scrollDisabled: false,
})

export const mutations = {
  finishLoading(state) {
    state.loadingAnimationFinished = true;
  },
  setPreviewMode(state, value) {
    state.previewMode = value;
  },
  setIsMobile(state, value) {
    state.isMobile = value;
  },
  setLenisScrollInitialized(state, value) {
    state.isLenisScrollInitialized = value;
  },
  setShowNav(state, bool) {
    state.showNav = bool;
  },
  setScrollToTarget(state, target) {
    state.scrollToTarget = target;
  },
  setScrollDisabled(state, value) {
    if (!state.isMobile) {
      state.scrollDisabled = value;
    } else {
      state.scrollDisabled = false
    }
  },
}

export const actions = {
  triggerScrollTo({commit}, target) {
    commit('setScrollToTarget', target);
  },
  triggerScrollDisabled({commit}, value) {
    commit('setScrollDisabled', value);
  },
  updateIsMobile({commit}, isMobile) {
    commit('setIsMobile', isMobile);
  },
}
