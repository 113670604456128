
export default {
  name: "Menu",
  data() {
    return {
      toggleMobileMenu: false,
      pages: {
        data: [
          {id: 1, attributes: {name: 'Product', href: '/product/'}, prioritize: true},
          {
            id: 2,
            attributes: {
              name: 'Use Cases',
              href: '',
              children: [
                {
                  name: 'Collect Emails for Marketing Campaigns',
                  mobileName: 'Collect Leads',
                  href: '/use-cases/collect-emails/'
                },
                {name: 'Feedback and Surveys', mobileName: 'Feedback & Surveys', href: '/use-cases/feedback-surveys/'},
                {
                  name: 'Announce Updates to Your Audience',
                  mobileName: 'Announcements',
                  href: '/use-cases/announce-updates/'
                },
                {name: 'Provide Coupons & Offers', mobileName: 'Coupons & Offers', href: '/use-cases/coupons-offers/'},
              ],
            },
            prioritize: true,
          },
          {id: 3, attributes: {name: 'Templates', href: '/templates/'}, prioritize: true},
          {id: 4, attributes: {name: 'Blog', href: '/blog/'}, prioritize: true},
          {id: 5, attributes: {name: 'Pricing', href: '/pricing/'}, prioritize: false, addMargin: true},
        ],
      },
      year: null,
      openSubmenus: [],
    };
  },
  computed: {
    loadingAnimationFinished() {
      return this.$store.state.loadingAnimationFinished;
    },
    showNav() {
      return this.$store.state.showNav;
    },
    getImageSrc() {
      if (this.$colorMode.value === 'dark') {
        return '/icon_white.png';
      } else {
        return '/icon.png';
      }
    },
  },
  watch: {
    '$route'(to, from) {
      this.$store.commit('setShowNav', true);
    },
  },
  mounted() {
    const now = new Date();
    this.year = now.getFullYear();
    this.initializeMenuOverlayAnimation();
    this.initializeMenuLinksAnimation();
  },
  methods: {
    initializeMenuOverlayAnimation() {
      // Anime js requires initial state to be set
      this.$anime.set(this.$refs['menuOverlay'], {
        translateX: '100%',
      });
    },
    initializeMenuLinksAnimation() {
      // Anime js requires initial state to be set
      const menuLinks = document.querySelectorAll('.menu-link');
      menuLinks.forEach((link) => {
        this.$anime.set(link, {
          translateY: '120%',
          opacity: 0,
        });
      });
    },
    triggerMobileNavAnimation() {
      this.toggleMobileMenu = !this.toggleMobileMenu;
      if (this.toggleMobileMenu) {
        document.body.classList.add('is-nav-open');
        this.menuOverlayAnimation(true);
        this.menuLinksAnimation(true);
      } else {
        this.menuOverlayAnimation(false);
        this.menuLinksAnimation(false);
        document.body.classList.remove('is-nav-open');
        // Close all open submenus when the mobile menu is closed
        this.openSubmenus = [];
      }
    },
    menuOverlayAnimation(show) {
      this.$anime({
        targets: this.$refs['menuOverlay'],
        translateX: show ? '0%' : '100%',
        duration: 500,
        easing: 'easeInOutQuad',
      });
    },
    menuLinksAnimation(show) {
      this.$anime({
        targets: '.menu-link',
        translateY: show ? '0%' : '120%',
        opacity: show ? 1 : 0,
        duration: 400,
        easing: 'easeInOutQuad',
        delay: this.$anime.stagger(60, {start: show ? 500 : 0}),
      });
    },
    toggleSubmenu(id) {
      const index = this.openSubmenus.indexOf(id);
      if (index > -1) {
        this.openSubmenus.splice(index, 1);
      } else {
        this.openSubmenus.push(id);
      }
    },
    isSubmenuOpen(id) {
      return this.openSubmenus.includes(id);
    },
  },
};
