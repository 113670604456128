import en from './static/lang/en'
// import bg from './static/lang/bg'

// default language
const defaultLocale = 'en'

// List of available language
const locales = [
  {
    code: 'en',
    iso: 'en-US',
    name: 'English'
    // isCatchallLocale: true
  },
  // {
  //   code: 'bg',
  //   iso: 'bg-BG',
  //   name: 'Bulgaria'
  //   // isCatchallLocale: true
  // }
]

// List of available message languages
const i18nLocales = {
  fallbackLocale: 'en',
  messages: { en } // bg
}

// List of available message languages
const i18nPages = {
    'blog/_slug': {
      en: '/blog/:slug',
      // bg: '/blog/:slug',
    },
    'support/_slug': {
      en: '/support/:slug',
      // bg: '/support/:slug',
    },
    'integrations/_slug': {
      en: '/integrations/:slug',
      // bg: '/support/:slug',
    },
    'use-cases/_slug': {
      en: '/use-cases/:slug',
      // bg: '/use-cases/:slug',
    },
    'templates/_slug': {
      en: '/templates/:slug',
      // bg: '/templates/:slug',
    },
  }

export { defaultLocale, locales, i18nLocales, i18nPages }
