
import { mapActions } from 'vuex';
import { debounce } from 'throttle-debounce';

export default {
  name: 'DefaultLayout',
 mounted() {
    this.updateIsMobileState();
    this.handleResizeDebounced = debounce(200, this.handleResize, { atBegin: false });
    window.addEventListener('resize', this.handleResizeDebounced);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResizeDebounced);
  },
  methods: {
    ...mapActions(['updateIsMobile']),

    updateIsMobileState() {
      const isMobile = this.$device.isMobile && window.innerWidth < 768;
      this.updateIsMobile(isMobile);
    },
    handleResize() {
      this.updateIsMobileState();
    },
  },
};
